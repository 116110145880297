import React, { ReactElement } from 'react'

import RBlog from '~components/RBlog'

// import Testimonials from '~components/Testimonials'

const Forms = (): ReactElement => (
  <>
    <RBlog />
    {/*<Testimonials />*/}
  </>
)

export default Forms
