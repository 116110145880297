import React, { ReactElement } from 'react'

import BlogBlock from '~components/RBlog'

// import TestimonialsBlock from '~components/Testimonials'

const Service = (): ReactElement => (
  <>
    <BlogBlock />
    {/*<TestimonialsBlock />*/}
  </>
)

export default Service
